import Styled from 'styled-components';

const TwoColumn = Styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;

  & > div {
    width: calc(50% - 48px);
    max-width: 720px;
  }
`;

const ThreeColumn = Styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .column {
    width: 33%;
    box-sizing: border-box;
    margin-right: 24px;
    display: flex;
  }

  .column:last-child {
    margin-right: 0;
  }
`;

const ThreeColumnCompare = Styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  max-width: 960px;
  margin: 24px auto 48px auto;
  box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.3);
  overflow-x: auto;

  & > div {
    width: calc(100% / 3 + 1px);
    max-width: 460px;
    min-width: 220px;
  }

  & > div:last-child {
    border-right: none;
  }

  & > div:nth-child(1) h4 {
    background-color: #39CCCC;
    color: white;
  }

  & > div:nth-child(2) h4 {
    background-color: #7FDBFF;
    color: white;
  }

  & > div:nth-child(3) h4 {
    background-color: #FF851B;
    color: white;
  }

  h4 {
    text-align: center;
    padding-top: 12px;
    padding-bottom: 12px;
    margin: 0;
    letter-spacing: 1.2px;
    text-shadow: 1px 1px rgba(0, 0, 0, .3);
    text-transform: uppercase;
  }

  ul {
    margin-top: 30px;
    margin-bottom: 30px;
    list-style-type: none;
    padding-left: 24px;
    padding-right: 24px;
  }

  li {
    margin-bottom: 24px;
  }

  @media screen and (max-width: 820px) {
    width: auto;
    max-width: auto;
    display: block;

    & > div {
      width: 100%;
      max-width: 100%;
    }
  }
`;

export { TwoColumn, ThreeColumn, ThreeColumnCompare };
