import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { growl } from '../redux/actions/notifications/actionCreators';

import Img from 'gatsby-image';
import { graphql } from 'gatsby';

import { ThemeProvider } from 'styled-components';
import theme from '../styles/theme';
import { ThreeColumn, ThreeColumnCompare } from '../styles/layout';

import { Formik, Form } from 'formik';
import axios from 'axios';

import Header from '../components/simpleHeader';
import Modal from '../primespot-ui/components/modal';
import Toggle from '../primespot-ui/components/toggle';
import Tooltip from '../primespot-ui/components/tooltip';
import ContactForm from '../components/contactForm';
import { TextInput, TextareaInput, PrimaryButton } from '../styles/forms';
import NotificationProvider from '../components/notificationProvider';
import TestimonialCard from '../components/testimonialCard';
import BeforeAfterImage from '../components/beforeAfterImage';

import config from '../config';

import {
  Wrapper,
  TestimonalSection,
  BeforeAfterSection,
} from '../styles/pages/carpet-cleaning-lp2';

export const query = graphql`
  query CarpetCleaningLanding2PageQuery {
    beforeAfterOne: file(relativePath: { regex: "/cc-gallery-1.jpg/" }) {
      childImageSharp {
        fixed(width: 283, height: 159) {
          ...GatsbyImageSharpFixed
        }
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beforeAfterTwo: file(relativePath: { regex: "/cc-gallery-2.jpg/" }) {
      childImageSharp {
        fixed(width: 283, height: 159) {
          ...GatsbyImageSharpFixed
        }
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beforeAfterThree: file(relativePath: { regex: "/cc-gallery-3.jpg/" }) {
      childImageSharp {
        fixed(width: 283, height: 159) {
          ...GatsbyImageSharpFixed
        }
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beforeAfterFour: file(relativePath: { regex: "/cc-gallery-4.jpg/" }) {
      childImageSharp {
        fixed(width: 283, height: 159) {
          ...GatsbyImageSharpFixed
        }
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    beforeAfterFive: file(relativePath: { regex: "/cc-gallery-5.jpg/" }) {
      childImageSharp {
        fixed(width: 283, height: 159) {
          ...GatsbyImageSharpFixed
        }
        fluid(maxWidth: 1080) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

class CarpetCleaningLandingPage2 extends Component {
  state = {
    activeSection: 'first-section',
    soilingLevel: '',
    odorLevel: '',
    stainingLevel: '',
  };

  render() {
    const { data } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <Wrapper>
            <Header />
            <section
              className={
                this.state.activeSection === 'first-section'
                  ? 'section first-section'
                  : 'section first-section hidden'
              }
            >
              <div className="inner-content">
                <p>
                  Welcome to our interactive carpet cleaning information hub.
                  Fill out the form below to get started. The answers you
                  provide will allow us to give you information that is specific
                  to your needs.
                </p>
                <hr />
                <span>My carpets</span>
                <Toggle>
                  {({
                    toggle: toggleSoilingLevelModal,
                    toggled: showSoilingLevelModal,
                  }) => (
                    <Fragment>
                      <Tooltip
                        show={!this.state.soilingLevel}
                        text="Click here to select soiling level"
                        textStyles={{ top: 'calc(100% - 18px)' }}
                      >
                        <div
                          className="inline-selector"
                          onClick={toggleSoilingLevelModal}
                        >
                          {this.state.soilingLevel
                            ? this.state.soilingLevel.text
                            : '. . .'}
                        </div>
                      </Tooltip>
                      {showSoilingLevelModal && (
                        <Modal onClose={toggleSoilingLevelModal}>
                          <div className="selector-content">
                            <ul>
                              <li
                                onClick={() => {
                                  this.setState({
                                    soilingLevel: {
                                      value: 4,
                                      text: 'are extremely dirty.',
                                    },
                                  });
                                  toggleSoilingLevelModal();
                                }}
                              >
                                Are extremely dirty
                              </li>
                              <li
                                onClick={() => {
                                  this.setState({
                                    soilingLevel: {
                                      value: 3,
                                      text: 'are somewhat dirty.',
                                    },
                                  });
                                  toggleSoilingLevelModal();
                                }}
                              >
                                Are somewhat dirty
                              </li>
                              <li
                                onClick={() => {
                                  this.setState({
                                    soilingLevel: {
                                      value: 2,
                                      text: 'are a little bit dirty.',
                                    },
                                  });
                                  toggleSoilingLevelModal();
                                }}
                              >
                                Are a little bit dirty
                              </li>
                              <li
                                onClick={() => {
                                  this.setState({
                                    soilingLevel: {
                                      value: 1,
                                      text: `aren't visibly dirty.`,
                                    },
                                  });
                                  toggleSoilingLevelModal();
                                }}
                              >
                                Aren't visibly dirty
                              </li>
                            </ul>
                          </div>
                        </Modal>
                      )}
                    </Fragment>
                  )}
                </Toggle>
                <span>They have</span>
                <Toggle>
                  {({
                    toggle: toggleOdorLevelModal,
                    toggled: showOdorLevelModal,
                  }) => (
                    <Fragment>
                      <Tooltip
                        show={!this.state.odorLevel}
                        text="Click here to select odor level"
                        textStyles={{ top: 'calc(100% - 18px)' }}
                      >
                        <div
                          className="inline-selector"
                          onClick={toggleOdorLevelModal}
                        >
                          {this.state.odorLevel
                            ? this.state.odorLevel.text
                            : '. . .'}
                        </div>
                      </Tooltip>
                      {showOdorLevelModal && (
                        <Modal onClose={toggleOdorLevelModal}>
                          <div className="selector-content">
                            <ul>
                              <li
                                onClick={() => {
                                  this.setState({
                                    odorLevel: {
                                      value: 5,
                                      text: 'a severe pet odor.',
                                    },
                                  });
                                  toggleOdorLevelModal();
                                }}
                              >
                                A severe pet odor
                              </li>
                              <li
                                onClick={() => {
                                  this.setState({
                                    odorLevel: {
                                      value: 4,
                                      text: 'a slight pet odor.',
                                    },
                                  });
                                  toggleOdorLevelModal();
                                }}
                              >
                                A slight pet odor
                              </li>
                              <li
                                onClick={() => {
                                  this.setState({
                                    odorLevel: {
                                      value: 3,
                                      text: 'a severe non-pet odor.',
                                    },
                                  });
                                  toggleOdorLevelModal();
                                }}
                              >
                                A severe non-pet odor
                              </li>
                              <li
                                onClick={() => {
                                  this.setState({
                                    odorLevel: {
                                      value: 2,
                                      text: 'a slight non-pet odor.',
                                    },
                                  });
                                  toggleOdorLevelModal();
                                }}
                              >
                                A slight non-pet odor
                              </li>
                              <li
                                onClick={() => {
                                  this.setState({
                                    odorLevel: { value: 1, text: 'no odor.' },
                                  });
                                  toggleOdorLevelModal();
                                }}
                              >
                                No odor
                              </li>
                            </ul>
                          </div>
                        </Modal>
                      )}
                    </Fragment>
                  )}
                </Toggle>
                <span>And they</span>
                <Toggle>
                  {({
                    toggle: toggleStainingLevelModal,
                    toggled: showStainingLevelModal,
                  }) => (
                    <Fragment>
                      <Tooltip
                        show={!this.state.stainingLevel}
                        text="Click here to select staining level"
                        textStyles={{ top: 'calc(100% - 18px)' }}
                      >
                        <div
                          className="inline-selector"
                          onClick={toggleStainingLevelModal}
                        >
                          {this.state.stainingLevel
                            ? this.state.stainingLevel.text
                            : '. . .'}
                        </div>
                      </Tooltip>
                      {showStainingLevelModal && (
                        <Modal onClose={toggleStainingLevelModal}>
                          <div className="selector-content">
                            <ul>
                              <li
                                onClick={() => {
                                  this.setState({
                                    stainingLevel: {
                                      value: 4,
                                      text: 'are very stained.',
                                    },
                                  });
                                  toggleStainingLevelModal();
                                }}
                              >
                                Are very stained
                              </li>
                              <li
                                onClick={() => {
                                  this.setState({
                                    stainingLevel: {
                                      value: 3,
                                      text: 'are somewhat stained.',
                                    },
                                  });
                                  toggleStainingLevelModal();
                                }}
                              >
                                Are somewhat stained
                              </li>
                              <li
                                onClick={() => {
                                  this.setState({
                                    stainingLevel: {
                                      value: 2,
                                      text: 'are only a little stained.',
                                    },
                                  });
                                  toggleStainingLevelModal();
                                }}
                              >
                                Are only a little stained
                              </li>
                              <li
                                onClick={() => {
                                  this.setState({
                                    stainingLevel: {
                                      value: 1,
                                      text: `aren't stained.`,
                                    },
                                  });
                                  toggleStainingLevelModal();
                                }}
                              >
                                Aren't stained
                              </li>
                            </ul>
                          </div>
                        </Modal>
                      )}
                    </Fragment>
                  )}
                </Toggle>
                <div>
                  <button
                    disabled={
                      !this.state.soilingLevel ||
                      !this.state.odorLevel ||
                      !this.state.stainingLevel
                    }
                    onClick={() => {
                      if (
                        this.state.soilingLevel &&
                        this.state.odorLevel &&
                        this.state.stainingLevel
                      ) {
                        this.setState({ activeSection: 'second-section' });
                      }
                    }}
                  >
                    Get started
                  </button>
                </div>
              </div>
            </section>
            <section
              className={
                this.state.activeSection === 'second-section'
                  ? 'section second-section'
                  : 'section second-section hidden'
              }
            >
              <Toggle>
                {({ toggle: toggleQuoteModal, toggled: showQuoteModal }) => (
                  <Fragment>
                    <Toggle>
                      {({
                        toggle: toggleContactModal,
                        toggled: showContactModal,
                      }) => (
                        <Fragment>
                          <div className="inner-wrapper">
                            <h2>What would you like to do?</h2>
                            <ThreeColumn>
                              <div className="column">
                                <div
                                  className="card red"
                                  onClick={toggleQuoteModal}
                                >
                                  <h3>Get a price quote</h3>
                                  <p>
                                    Click here to get a custom price quote for
                                    your specific needs.
                                  </p>
                                </div>
                              </div>
                              <div className="column">
                                <div
                                  className="card green"
                                  onClick={() =>
                                    this.setState({
                                      activeSection: 'information-section',
                                    })
                                  }
                                >
                                  <h3>
                                    Get more information specific to your needs
                                  </h3>
                                  <p>
                                    Click here to get information about carpet
                                    cleaning specific to your situation.
                                  </p>
                                </div>
                              </div>
                              <div className="column">
                                <div
                                  className="card blue"
                                  onClick={toggleContactModal}
                                >
                                  <h3>Talk to a professional</h3>
                                  <p>
                                    Click here to get in touch with a
                                    professional regarding your specific needs.
                                  </p>
                                </div>
                              </div>
                            </ThreeColumn>
                          </div>
                          {showQuoteModal && (
                            <Modal onClose={toggleQuoteModal}>
                              <h2 style={{ textAlign: 'center' }}>
                                Get a price quote
                              </h2>
                              <Formik
                                initialValues={{
                                  name: '',
                                  email: '',
                                  phoneNumber: '',
                                  details: '',
                                }}
                                onSubmit={(values, { setSubmitting }) => {
                                  const data = Object.assign({}, values, {
                                    source:
                                      'Second carpet cleaning landing page',
                                    blackbox: {
                                      soilingLevel: this.state.soilingLevel
                                        .text,
                                      odorLevel: this.state.odorLevel.text,
                                      stainingLevel: this.state.stainingLevel
                                        .text,
                                    },
                                  });
                                  axios
                                    .post(`${config.backendServer}/leads`, data)
                                    .then(res => {
                                      if (res.status === 201) {
                                        growl({
                                          type: 'success',
                                          message:
                                            'Your request for a price quote has been submitted.  We will be in touch soon!',
                                        });
                                      } else {
                                        growl({
                                          type: 'danger',
                                          message:
                                            'Something went wrong.  Please try again.',
                                        });
                                      }
                                    })
                                    .catch(err => {
                                      console.error(err);
                                      growl({
                                        type: 'danger',
                                        message:
                                          'Something went wrong.  Please try again.',
                                      });
                                    })
                                    .finally(() => {
                                      setSubmitting(false);
                                    });
                                }}
                              >
                                {({
                                  values,
                                  handleChange,
                                  handleBlur,
                                  isSubmitting,
                                }) => (
                                  <Form className="quote-form">
                                    <label>
                                      <span>Your name</span>
                                      <TextInput
                                        name="name"
                                        type="text"
                                        value={values.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </label>
                                    <label>
                                      <span>Your email address</span>
                                      <TextInput
                                        name="email"
                                        type="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </label>
                                    <label>
                                      <span>Your phone number</span>
                                      <TextInput
                                        name="phoneNumber"
                                        type="text"
                                        value={values.phoneNumber}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </label>
                                    <label>
                                      <span>
                                        Details (number of rooms, etc.)
                                      </span>
                                      <TextareaInput
                                        name="details"
                                        value={values.details}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                      />
                                    </label>
                                    <PrimaryButton
                                      disabled={isSubmitting}
                                      type="submit"
                                    >
                                      Get quote
                                    </PrimaryButton>
                                  </Form>
                                )}
                              </Formik>
                            </Modal>
                          )}
                          {showContactModal && (
                            <Modal onClose={toggleContactModal}>
                              <ContactForm
                                growl={growl}
                                blackbox={{
                                  'Soiling level': this.state.soilingLevel.text,
                                  'Odor level': this.state.odorLevel.text,
                                  'Staining level': this.state.stainingLevel
                                    .text,
                                }}
                              />
                            </Modal>
                          )}
                        </Fragment>
                      )}
                    </Toggle>
                  </Fragment>
                )}
              </Toggle>
            </section>
            <section
              className={
                this.state.activeSection === 'information-section'
                  ? 'section information-section'
                  : 'section information-section hidden'
              }
            >
              {generateInformationReport({
                soilingLevel: this.state.soilingLevel.value,
                odorLevel: this.state.odorLevel.value,
                stainingLevel: this.state.stainingLevel.value,
              })}
              <h2 style={{ textAlign: 'center', margin: '48px 0 24px 0' }}>
                Our Standard Packages
              </h2>
              <ThreeColumnCompare>
                <div>
                  <h4>Basic cleaning</h4>
                  <ul>
                    <li>Hot water extraction (steam cleaning)</li>
                    <li>Use of industry-standard wand tool</li>
                    <li>Basic spot/stain removal included</li>
                    <li>Moving of small/simple items included</li>
                    <li>6 to 12 hour estimated drying time</li>
                    <li>Recommended in most situations</li>
                  </ul>
                </div>
                <div>
                  <h4>Low moisture cleaning</h4>
                  <ul>
                    <li>Very-low-moisture cleaning</li>
                    <li>Use of specialized equipment for drier cleaning</li>
                    <li>Basic spot/stain removal included</li>
                    <li>Moving of small/simple items included</li>
                    <li>2 to 4 hour estimated drying time</li>
                    <li>Recommended when drying time is a concern</li>
                  </ul>
                </div>
                <div>
                  <h4>Deep cleaning</h4>
                  <ul>
                    <li>Hot water extraction (steam cleaning)</li>
                    <li>Use of specialized rotary scrubbing tool</li>
                    <li>Heavy traffic lane cleaning</li>
                    <li>Basic spot/stain removal included</li>
                    <li>Moving of small/simple items included</li>
                    <li>6 to 12 hour estimated drying time</li>
                    <li>
                      Recommended when the carpets are extra dirty or haven't
                      been cleaned in several years
                    </li>
                  </ul>
                </div>
              </ThreeColumnCompare>
              <h2 style={{ textAlign: 'center', margin: '48px 0 24px 0' }}>
                We also offer a green-clean package!
              </h2>
              <p>
                Our standard cleaners are very environmentally and pet friendly.
                In the situations where you want to be extra kind to the
                environment, however, we offer a green-clean package.
              </p>
              <p>
                In this package, we use only environmental alternatives to our
                most popular cleaners. These cleaners substitute harsher
                ingredients for those that are milder alternatives. While our
                most popular cleaners are the strongest and best that we use,
                the environmental alternatives are extremely good as well. They
                can get the job done in all but the toughest situations.
              </p>
              <TestimonalSection>
                <h2>See What Our Clients Are Saying About Us</h2>
                <div className="testimonial-section__testimonials">
                  <TestimonialCard>
                    <blockquote>
                      <p>
                        I had very little expectation that this carpet could
                        even look halfway decent. I was getting it cleaned to
                        help with a mold issue in this old house we are renting.
                        I had to be gone for a day or so and during this time
                        they came in to clean the carpet. When I got back and
                        opened the door, I thought I was at the wrong house. It
                        was amazing. They got things out of the carpet that I
                        thought were permanent. Amazing results!
                      </p>
                      <footer>Anonymous</footer>
                    </blockquote>
                  </TestimonialCard>
                  <TestimonialCard>
                    <blockquote>
                      <p>
                        Very quick response to our request. Technician showed up
                        exactly on time; was very knowledgable, professional,
                        and pleasant. Work done quickly and looks great. Price
                        very reasonable - and how it was priced was clearly
                        explained before service contract agreed and at the time
                        of payment.
                      </p>
                      <footer>Harris J.</footer>
                    </blockquote>
                  </TestimonialCard>
                  <TestimonialCard>
                    <blockquote>
                      <p>
                        Great Service! We were worked into a busy schedule on
                        short notice and the carpets looked great. Also very
                        well priced.
                      </p>
                      <footer>Jason W.</footer>
                    </blockquote>
                  </TestimonialCard>
                  <TestimonialCard>
                    <blockquote>
                      <p>
                        Since I moved to northern Virginia I cannot find a
                        carpet company as good as you. Do you know of a good
                        company near Alexandria? I still own a townhouse in
                        Christiansburg and use your services every year. I
                        appreciate the excellent results! It is so hard to find
                        a quality and honest company.
                      </p>
                      <footer>Melissa</footer>
                    </blockquote>
                  </TestimonialCard>
                  <TestimonialCard>
                    <blockquote>
                      <p>
                        I needed a large room with wall to wall carpeting
                        cleaned, also an area rug and a set of stairs. In
                        addition, they took away a wool area rug to be cleaned
                        at their place of business.
                      </p>
                      <p>
                        Very well. I was very happy with the results. I felt
                        that the fee was reasonable, though I don't remember
                        exactly what I paid at the time. The person I talked to
                        when I made the appointment, Michael, was very helpful
                        and answered my questions and concerns regarding pet
                        odor on the wall to wall carpeting. I will call them
                        again when the carpets need cleaning.
                      </p>
                      <footer>Anonymous</footer>
                    </blockquote>
                  </TestimonialCard>
                  <TestimonialCard>
                    <blockquote>
                      <p>
                        Sci-Tech saved me thousands on carpet replacement. Who
                        would have thought that purple nail polish could come
                        out of white carpet. I thank you and my daughter thanks
                        you.
                      </p>
                      <footer>Nancy</footer>
                    </blockquote>
                  </TestimonialCard>
                </div>
              </TestimonalSection>
              <BeforeAfterSection>
                <h2>Check Out A Few Before / After Pictures</h2>
                <div className="before-after-section__inner-wrapper">
                  <BeforeAfterImage
                    thumbnail={
                      <Img fixed={data.beforeAfterOne.childImageSharp.fixed} />
                    }
                    image={
                      <Img fluid={data.beforeAfterOne.childImageSharp.fluid} />
                    }
                  >
                    <p>
                      This berber carpet was quite filthy. By pre-treating it
                      before cleaning, we were able to remove nearly all of the
                      dirt in the carpet.
                    </p>
                  </BeforeAfterImage>
                  <BeforeAfterImage
                    thumbnail={
                      <Img fixed={data.beforeAfterTwo.childImageSharp.fixed} />
                    }
                    image={
                      <Img fluid={data.beforeAfterTwo.childImageSharp.fluid} />
                    }
                  >
                    <p>
                      This is a different angle of the same carpet in the image
                      before. This carpet was cleaned using our basic cleaning
                      package.
                    </p>
                  </BeforeAfterImage>
                  <BeforeAfterImage
                    thumbnail={
                      <Img
                        fixed={data.beforeAfterThree.childImageSharp.fixed}
                      />
                    }
                    image={
                      <Img
                        fluid={data.beforeAfterThree.childImageSharp.fluid}
                      />
                    }
                  >
                    <p>
                      See the difference in this carpet? This is a thick and
                      cream colored nylon carpet. It looked like new after
                      cleaning.
                    </p>
                  </BeforeAfterImage>
                  <BeforeAfterImage
                    thumbnail={
                      <Img fixed={data.beforeAfterFour.childImageSharp.fixed} />
                    }
                    image={
                      <Img fluid={data.beforeAfterFour.childImageSharp.fluid} />
                    }
                  >
                    <p>
                      This was a large basement carpet that we cleaned. The
                      outside edges of the picture are the areas we've already
                      cleaned. Notice how much brighter the carpet looks.
                    </p>
                  </BeforeAfterImage>
                  <BeforeAfterImage
                    thumbnail={
                      <Img fixed={data.beforeAfterFive.childImageSharp.fixed} />
                    }
                    image={
                      <Img fluid={data.beforeAfterFive.childImageSharp.fluid} />
                    }
                  >
                    <p>
                      This is a picture of our deluxe package on display. The
                      swirl marks in the carpet are from the rotary machine. The
                      dark traffic areas in this carpet are being completely
                      removed.
                    </p>
                  </BeforeAfterImage>
                </div>
              </BeforeAfterSection>
              <ContactForm
                growl={growl}
                blackbox={{
                  'Soiling level': this.state.soilingLevel.text,
                  'Odor level': this.state.odorLevel.text,
                  'Staining level': this.state.stainingLevel.text,
                }}
              />
            </section>
          </Wrapper>
          <NotificationProvider />
        </Fragment>
      </ThemeProvider>
    );
  }
}

const generateInformationReport = ({
  soilingLevel,
  odorLevel,
  stainingLevel,
}) => {
  return (
    <Fragment>
      {soilingLevel === 1 && (
        <p>
          Your carpets aren't visibly soiled. This means that you likely don't
          need a deep-cleaning package for soiling alone. Despite the fact that
          you don't see visible dirt, there is likely significant soiling
          beneath the surface of the carpet. Cleaning your carpets will remove
          this dirt. This can help improve the longevity of your carpets as well
          as reduce allergens.
        </p>
      )}
      {soilingLevel === 2 && (
        <p>
          Your carpets aren't too dirty. Cleaning them professionally will
          likely yield excellent results. You can probably expect just about all
          of the spots to be removed.
        </p>
      )}
      {soilingLevel === 3 && (
        <p>
          Given the level of visible dirt in the carpet, the carpets probably
          should have been cleaned earlier. It's probably safe to assume that
          cleaning your carpets will still yield good results. But your probably
          will need a deep-cleaning package.
        </p>
      )}
      {soilingLevel === 4 && (
        <p>
          You have suggested that your carpets are filthy. It's possible that it
          might be too late to properly restore your carpets. They will
          certainly need a deep-cleaning package to give the best chance of good
          results.
        </p>
      )}
      {/* Severe pet odor */}
      {odorLevel === 5 && (
        <p>
          Pet odor can be a difficult problem to deal with. Given that your
          carpets have a severe pet odor problem, it's possible that carpet
          replacement might be the safest option. In many situations, pet odor
          issues can penetrate into the padding of the carpet. If this is the
          case, carpet cleaning alone won't be enough.
        </p>
      )}
      {/* Slight pet odor */}
      {odorLevel === 4 && (
        <p>
          We offer a pet topical treatment that very often is effective at
          dealing with pet odors. This product is most often sprayed onto the
          carpet after a fresh cleaning to help eliminate pet odors.
        </p>
      )}
      {/* Severe non-pet odor */}
      {odorLevel === 3 && (
        <p>
          We offer a range of high-powered deodorizing products that can help
          deal with odor problems. These products are only effective at removing
          odors from the carpet, however. In many situations, odor issues can be
          coming from other sources than just the carpets. This might include
          walls, air ducts, baseboards, furniture, etc.
        </p>
      )}
      {/* Slight non-pet odor */}
      {odorLevel === 2 && (
        <p>
          Carpet cleaning alone might be enough to deal with the odor problem.
          To be extra safe, we can apply a mild deodorizer to the carpet after
          cleaning to take care of any residual odors that might linger.
        </p>
      )}
      {/* No odor */}
      {odorLevel === 1 && (
        <p>
          Since your carpet has no odor problems, a deodorizing product won't be
          needed. A standard carpet cleaning can help your carpets to smell
          fresh and remove any allergens that might be hiding in the carpet.
        </p>
      )}
      {/* Very stained */}
      {stainingLevel === 4 && (
        <p>
          While many types of stains come out of carpets during cleaning, some
          do not. Given that you have listed your carpet as being very stained,
          it's likely that you have some types of stains that are difficult to
          remove. Color stains (red, yellow, blue, etc.) can be very problematic
          and almost always require additional treatment, for example. It is
          also highly likely that not all stains will be removable.
        </p>
      )}
      {/* Somewhat stained */}
      {stainingLevel === 3 && (
        <p>
          While many types of stains come out of carpets during cleaning, some
          do not. Given that you have listed your carpet as being significantly
          stained, it's likely that you have some types of stains that are
          difficult to remove. Color stains (red, yellow, blue, etc.) can be
          very problematic and almost always require additional treatment, for
          example. It is also highly likely that not all stains will be
          removable.
        </p>
      )}
      {/* Slightly stained */}
      {stainingLevel === 2 && (
        <p>
          Stains will often come out with carpet cleaning alone. Sometimes
          stains require additional treatment to be removed, however. We stock a
          large selection of specialty stain removers. We have success removing
          stains such as color stains (red, blue, yellow, etc.), makeup, blood,
          drink spills, food spills, and many more.
        </p>
      )}
      {/* Aren't stained */}
      {stainingLevel === 1 && (
        <p>
          When carpets aren't particularly stained, it makes cleaning much
          easier. Since your carpets aren't stained, you don't need to worry
          about any specialty spot removal being needed.
        </p>
      )}
    </Fragment>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    growl: ({ type, message }) => dispatch(growl({ type, message })),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(CarpetCleaningLandingPage2);
