import React from 'react';
import PropTypes from 'prop-types';

import Styled from 'styled-components';

const Tooltip = ({ show, children, text, textStyles }) => {
  return (
    <Wrapper>
      {children}
      <div
        className={show ? 'tooltip__text' : 'tooltip__text hidden'}
        style={textStyles}
      >
        <div className="tooltip__arrow" />
        {text}
      </div>
    </Wrapper>
  );
};

Tooltip.propTypes = {
  // True to make tooltip visible.
  show: PropTypes.bool,
  // Activator.
  children: PropTypes.any.isRequired,
  // Text (or nodes) to display when tooltip is visible.
  text: PropTypes.any.isRequired,
  // Styles for the text.
  textStyles: PropTypes.object,
};

Tooltip.defaultProps = {
  show: false,
};

const Wrapper = Styled.div`
  position: relative;
  display: inline-block;

  .tooltip__text {
    padding: 12px;
    background-color: hsl(204, 86%, 53%);
    color: white;
    position: absolute;
    top: 100%;
    font-size: 16px;
    border-radius: 4px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, .2);
    transition: all .3s ease;
  }

  .tooltip__text.hidden {
    opacity: 0;
    pointer-events: none;
  }

  .tooltip__arrow {
    width: 12px;
    height: 12px;
    background-color: hsl(204, 86%, 53%);
    position: absolute;
    bottom: calc(100% - 6px);
    transform: rotate(45deg);
  }
`;

export default Tooltip;
