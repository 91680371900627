import Styled from 'styled-components';

import { fullWidthMixin } from '../mixins';

const Wrapper = Styled.div`
  font-family: ${({ theme }) => theme.primary.font.fontFamily};
  font-size: ${({ theme }) => theme.primary.font.sizes.normal.fontSize};
  box-sizing: border-box;
  padding-top: 50px;

  p, span {
    color: ${({ theme }) => theme.primary.colors.text.black}
  }

  p {
    line-height: ${({ theme }) => theme.primary.font.sizes.normal.lineHeight};
  }

  span {
    margin: 12px;
    display: inline-block;
  }

  hr {
    border: none;
    border-bottom: 1px solid ${({ theme }) =>
      theme.primary.colors.gray.lighter};
  }

  .section {
    width: 100vw;
    min-height: calc(100vh - 50px);
    box-sizing: border-box;
    padding: 24px;
    transition: all .3s ease;
    opacity: 1;
    pointer-events: auto;
    margin-top: 0;
    z-index: -1;

    &.hidden {
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 50px;
      min-height: 0;
      height: 0;
      margin-top: 48px;
      overflow: hidden;
    }
  }

  .first-section {
    background-color: ${({ theme }) => theme.primary.colors.gray.lightest};

    button {
    background: ${({ theme }) => theme.primary.colors.lightBlue.base};
    color: ${({ theme }) => theme.primary.colors.text.lightBlue.lightest};
    border-radius: 40px;
    padding: 12px 24px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, .2);
    margin: 60px;
    cursor: pointer;
    transition: all .3s ease;
    outline: none;
    border: none;

    &:hover {
      box-shadow: 0 4px 2px rgba(0, 0, 0, .3);
      margin-top: 59px;
    }

    &:active {
      margin-top: 61px;
      box-shadow: 0 2px 2px rgba(0, 0, 0, .2);
    }

    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  }

  .second-section {

    .inner-wrapper {
      padding-top: 48px;
      max-width: 860px;
      margin: 0 auto;
    }
    
    h2 {
      text-align: center;
      margin-bottom: 48px;
    }
  }

  .quote-form {
    width: 320px;
    margin: 0 auto;
  }

  .quote-form label {
    display: block;
    width: 320px;
  }

  .quote-form label span {
    display: block;
    margin: 6px 0;
  }

  .quote-form textarea {
    width: 280px;
    height: 150px;
  }

  .inner-content {
    font-size: ${({ theme }) => theme.primary.font.sizes.large.fontSize};
    text-align: center;
    box-sizing: border-box;
  }

  .inline-selector {
    border-bottom: 1px dotted rgb(50, 50, 50);
    border-width: 6px;
    padding: 24px;
    display: inline-block;
    color: #444;
    cursor: pointer;
    margin-bottom: 24px;
    display: inline-block;
    margin-right: 24px;
    min-width: 180px;
  }

  .selector-content {
    ul {
      list-style-type: none;
      padding-left: 0;
    }

    li {
      width: 100%;
      box-sizing: border-box;
      padding: 12px;
      transition: all .3s ease;
      cursor: pointer;

      &:hover {
        background-color: rgb(240, 240, 240);
        background-color: ${({ theme }) => theme.primary.colors.gray.lightest};
      }
    }
  }

  .card {
    min-height: 120px;
    min-width: 120px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
    border-radius: 4px;
    display: inline-block;
    display: inline-flex;
    flex-direction: column;
    cursor: pointer;
    transition: all .3s ease;
    box-sizing: border-box;

    h3 {
      margin-top: 12px;
      margin-bottom: 0;
      padding: 12px;
    }

    p {
      padding: 12px;
      flex-grow: 1;
      margin: 0;
    }

    &:hover {
      box-shadow: 0 2px 8px rgba(0, 0, 0, .35);
    }
  }

  .card.red {
    border-top: 6px solid ${({ theme }) => theme.primary.colors.red.base};
  }

  .card.green {
    border-top: 6px solid ${({ theme }) => theme.primary.colors.green.base};
  }

  .card.blue {
    border-top: 6px solid ${({ theme }) => theme.primary.colors.darkBlue.base};
  }
`;

const TestimonalSection = Styled.div`
  ${fullWidthMixin};

  h2 {
    text-align: center;
  }

  .testimonial-section__testimonials {
    display: flex;
    overflow-x: auto;
  }

  .testimonial-card {
    width: 35%;
    min-width: 35%;
  }

  @media screen and (max-width: 960px) {
    .testimonial-card {
      width: 52%;
      min-width: 52%;
    }
  }

  @media screen and (max-width: 720px) {
    .testimonial-card {
      width: 70%;
      min-width: 70%;
    }
  }
`;

const BeforeAfterSection = Styled.div`
  ${fullWidthMixin};
  padding-left: 48px;
  padding-right: 48px;
  box-sizing: border-box;

  h2 {
    text-align: center;
  }

  .before-after-section__inner-wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    max-width: 100%;
    flex-wrap: wrap;
  }

  @media screen and (max-width: 776px) {
    .before-after-section__inner-wrapper {
      justify-content: center;
    }
  }

  @media screen and (max-width: 460px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export { Wrapper, TestimonalSection, BeforeAfterSection };
